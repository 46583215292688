import { motion } from "framer-motion";
import React, { useState, useEffect } from "react";
import { images } from "../../constants";
import { client } from "../../client";

import "./TenderDetails.scss";

const TenderDetails = () => {
  const [toggle, setToggle] = useState(false);
  const [tenders, setTenders] = useState([]);

  useEffect(() => {
    const query = '*[_type == "tender"]';

    client.fetch(query).then((data) => setTenders(data));
  }, []);

  return (
    <div className="app__tender" id="tender">
      <div className="app__tender-flex-left">
        <a href={``} onClick={() => setToggle(false)}>
          <img src={images.palyazat_logo} className="imgFixed" alt="Pályázat logo"/> 
        </a>
        <p className="app__tender-flex-left-title">Pályázat</p>
        {tenders.map((tender, idx) => (
          <div className="app__tender-flex-left-content">
            <h4 key={idx}>A projekt tartalmának bemutatása:</h4>
            <p>{tender.content}</p>
          </div>
        ))}
      </div>

      <div className="app__tender-flex-right">
        <div className="app__tender-flex-right-images">
          <motion.div
            whileInView={{ y: [140, 0], opacity: [0, 1] }}
            transition={{ duration: 0.8 }}
          >
            {tenders.map((tender, idx) => (
              <div className="app__tender-flex-right-content">
                <motion.div
                  whileInView={{ y: [-140, 0], opacity: [0, 1] }}
                  transition={{ duration: 0.8 }}
                >
                  <h4 key={idx}>Projekt címe:</h4>
                  <p>{tender.title}</p>
                  <h4 key={idx}>Kedvezményezett neve:</h4>
                  <p>{tender.company}</p>
                  <h4 key={idx}>Projekt azonosító száma:</h4>
                  <p>{tender.identifer}</p>
                  <h4 key={idx}>Szerződött támogatás összege:</h4>
                  <span className="valueSum">{tender.valueSum}</span>
                  <h4 key={idx}>Támogatás mértéke:</h4>
                  <div className="percentage">{tender.percentage}</div>
                  <h4 key={idx}>
                    Projekt megvalósításának tényleges befejezési dátuma:
                  </h4>
                  <p>{tender.date}</p>
                </motion.div>
              </div>
            ))}
          </motion.div>
          <motion.div
            whileInView={{ y: [70, 0], opacity: [0, 1] }}
            transition={{ duration: 0.6 }}
            className="app__activity-flex-left"
          >
            <img
              src={images.palyazat_logo}
              className="img"
              alt="Pályázat logo"
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default TenderDetails;
