import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { images } from "../../constants";
import { client } from "../../client";

import "./About.scss";

const About = () => {
  const [about, setAbout] = useState({});

  useEffect(() => {
    const query = '*[_type == "about"]';

    client.fetch(query).then((data) => setAbout(data[0]));
  }, []);

  return (
    <>
    <div className="app__about" id="about">
      <motion.div
        whileInView={{ y: [-200, 0], opacity: [0, 1] }}
        transition={{ duration: 0.6 }}
        className="app__about-flex-left"
      >
        <div>
          <p className="app__about-flex-left-title">{about.title}</p>
          <h1 className="app__about-flex-left-content">
            {about.description}
          </h1>
        </div>
      </motion.div>

      <motion.div
        whileInView={{ opacity: [0, 1] }}
        transition={{ duration: 0.5 }}
        className="app__about-flex-right"
      >
        <img src={images.hgh} className="img" alt="desk" />
      </motion.div>
    </div>
    </>
  );
};

export default About;
