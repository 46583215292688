import { motion } from "framer-motion";
import React from "react";

import "./Contact.scss";

const Contact = () => {
  return (
    <div className="app__contact" id="contact">
      <motion.div
        whileInView={{ y: [0, 0], opacity: [0, 1] }}
        transition={{ duration: 0.6 }}
        className="app__contact-flex-left"
      >
        <p className="app__contact-flex-left-title">Elérhetőség</p>
        <br></br>
        <motion.div
          whileInView={{ y: [-140, 0], opacity: [0, 1] }}
          transition={{ duration: 0.6 }}
        >
          <p className="app_contact-flex-company">HGH-BAU Kft.</p>
        </motion.div>
        <motion.div
          whileInView={{ y: [-140, 0], opacity: [0, 1] }}
          transition={{ duration: 0.8 }}
        >
          <p className="app_contact-flex-phone">Tel.: +36 62 555 160</p>
        </motion.div>
        <motion.div
          whileInView={{ y: [-140, 0], opacity: [0, 1] }}
          transition={{ duration: 1.0 }}
        >
          <p className="app_contact-flex-email">E-mail: hghbaukft@hotmail.com</p>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Contact;
